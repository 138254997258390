import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { dropPlugin } from '@react-pdf-viewer/drop';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';
import { Search } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import WordsGrid from './WordsGrid';
import ReactPdfViewer from './ReactPdfViewer';


function App() {
    const [keywords, setKeywords] = useState([{ word: "CLT", backgroundColor: "white" }])
    const [results, setResults] = useState([])
    const [selectValue, setSelectValue] = useState("CLT")
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const dropPluginInstance = dropPlugin();
    const searchPluginInstance = searchPlugin();
    const { Search } = searchPluginInstance;

    return (<ReactPdfViewer defaultLayoutPluginInstance={defaultLayoutPluginInstance} searchPluginInstance={searchPluginInstance} dropPluginInstance={dropPluginInstance}/>
    );
}

export default App;
