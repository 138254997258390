import { Viewer, Worker } from '@react-pdf-viewer/core'
import React from 'react'
import WordsGrid from './WordsGrid'

const ReactPdfViewer = ({searchPluginInstance, defaultLayoutPluginInstance, dropPluginInstance}) => {
  return (
    <>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
            <WordsGrid searchPluginInstance={searchPluginInstance} />
            <div
                style={{
                    height: '550px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                >

                {true && <Viewer
                    fileUrl={`https://s23.q4cdn.com/202968100/files/doc_downloads/test.pdf`}
                    plugins={[
                        defaultLayoutPluginInstance,
                        searchPluginInstance,
                        dropPluginInstance
                    ]}
                    />}
            </div>
        </Worker>
            </>

  )
}

export default ReactPdfViewer