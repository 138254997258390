import React, {useState} from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, 200px);
grid-auto-rows: minmax(50px,3fr);
gap: 10px;
padding-top: 30px;
padding-bottom: 20px;
text-align: center;
padding-left: 30px;
`

const slidein = keyframes`
from {
  opacity: 0
}

to {
  opacity: 1
}
`

const Card = styled.div`
background-color: ${props => props.backgroundColor === "white" ? "white" : props.backgroundColor};
display: flex;
height: 50px;
position: relative;
/* background-color: white; */
align-items: center;
justify-content: center;
padding-bottom: 5px;
transition: filter 200ms;
box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1);
border-radius: 3px;
animation-duration: 1s;
animation-name: ${slidein};
&:hover {
  cursor: pointer;
  filter: brightness(0.98)
}
`



const WordsGrid = ({ searchPluginInstance }) => {

  const [searchTermList, setSearchTermList] = useState([
    {searchTerm: "PDF", found: false},
    {searchTerm: "Charles", found: false},
    {searchTerm: "Adobe", found: false},
    {searchTerm: "Adb", found: false},
    {searchTerm: "read this", found: false}
  ])

  const handleCardClick = (e) => {
    if (e.target.children.length > 0) {
      searchPluginInstance.highlight(e.target.children[0].innerHTML)
    }
    // else {
    //   searchPluginInstance.highlight(e.target.innerHTML)
    // }
  }
  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     searchPluginInstance.highlight(e.target.innerHTML).then((result) => {
  //       console.log(result)
  //       if(result.length === 0) {
  //         alert("No results found")
  //       }
  //     })
  //     e.currentTarget.blur()
  //   }
  // }

    const handleKeyDown = (e) => {
      let searchTermListCopy = [...searchTermList]
      searchTermListCopy.forEach((searchTerm, index, array) => {
        searchPluginInstance.highlight(searchTerm.searchTerm).then((result) => {
          console.log(result)
          // console.log(result)
          if(result.length === 0) {
            searchTermListCopy[index] = {searchTerm: searchTerm.searchTerm, found: false}
            // alert("No results found")
          }
          else{
            // alert(index)
            searchTermListCopy[index] = {searchTerm: searchTerm.searchTerm, found: true}
            // searchTermListCopy.find(searchTerm => searchTerm.searchTerm === searchTerm.searchTerm).found = true
            // setSearchTermList([...searchTermListCopy.filter(searchTerm => searchTerm.searchTerm !== searchTerm.searchTerm), {searchTerm: searchTerm.searchTerm, found: true}])
          }
        }
        )
      }
      )
      console.log(searchTermList)
      setSearchTermList(searchTermListCopy)


      e.currentTarget.blur()
  }

  return (
    <Container>

        <button onClick={handleKeyDown}>Go</button>
      {searchTermList.map((searchTerm) => {
        return <Card backgroundColor={searchTerm.found ? "lightgreen" : "red"} onClick={handleCardClick} key={searchTerm.searchTerm}><div contentEditable suppressContentEditableWarning={true} onKeyDown={handleKeyDown}>{`${searchTerm.searchTerm} - ${searchTerm.found}`}</div></Card>
      })}
    </Container>
  )
}

export default WordsGrid
